import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel';
import Sub_header from '../Sub_header';
import '../../components/loader.css';
import { useNavigate, useParams } from 'react-router-dom'
import TablePagination from '@mui/material/TablePagination';

const Master_new_customer = () => {
  const{customer_id} = useParams();
    const [carriers, setCarriers] = React.useState([]); 
    console.log("carriers ===== " , carriers)
  const [selectedCarrier, setSelectedCarrier] = React.useState({ carrier_id:"" , customer_code:"" ,api_key:"",customer_id:customer_id }); 
  console.log("selectedCarrier ===== " , selectedCarrier)
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
      const homeSection = document.querySelector('.home-section');
      if (homeSection) {
          homeSection.classList.toggle('sidebar-closed');
      }
  };

  
  const fetchCarriers = () => {
   
    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/carrier_list`;
    let sendData = {  dstatus:1,
        indexValue:0,
        limit:0 ,
        customer_id:customer_id};
   
    axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
        console.log("res ", res);
       
        setCarriers(res.data.output)
      
            setSelectedCarrier({...selectedCarrier, carrier_id:res.data.output[0].carrier_id , customer_code:res.data.customer[0].customer_code!=undefined?res.data.customer[0].customer_code:"",api_key:res.data.customer[0].api_key!=undefined?res.data.customer[0].api_key:"" , customer_id:customer_id})
    
    }).catch((e) => {


    })
   
  }

  const handleCarrierSelection = (carrier) => {
    console.log("carrier ====== " , carrier)
    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/customer_carrier_list`;
    let sendData = {  dstatus:1,
        indexValue:0,
        limit:0 ,
        customer_id:customer_id,
    carrier_id:carrier.carrier_id};
   
    axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
        console.log("res= === ", res);
        if(res.data.output.length==0){
            setSelectedCarrier({...selectedCarrier, customer_id:customer_id , carrier_id:carrier.carrier_id ,customer_code:"" ,api_key:"" });
        }
        else{
            setSelectedCarrier({...selectedCarrier, customer_id:customer_id , carrier_id:carrier.carrier_id ,customer_code:res.data.output[0].customer_code ,api_key:res.data.output[0].api_key });
        }
      
    }).catch((e) => {


    })
   
  };

  const inputHandleChange = (e) => {
    console.log("e ", e.target.name);
    console.log("e ", e.target.value);
    console.log("e ", e.target.checked);

    if(e.target.type=="radio"){
        if(e.target.checked==true){
       if(e.target.name=="production_mode"){
        setSelectedCarrier({...selectedCarrier,production_mode:true , demo_mode:false}) 
       
        }
        else if(e.target.name=="demo_mode"){
              setSelectedCarrier({...selectedCarrier,demo_mode:true , production_mode:false}) 
            // setSelectedCarrier({...selectedCarrier,production_mode:false}) 
        }
        }
    }
    else{
    setSelectedCarrier({...selectedCarrier , [e.target.name]:e.target.value})
    }

}

// const changeStatus = (updateData) => {


//     let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/update_label_status_carrier`;
//     let sendData = {carrier_id:updateData.carrier_id , label_status:updateData.label_status };
//     console.log("sendData",sendData)
   
//     axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
//         console.log("res ", res);
//         const Toast = Swal.mixin({
//             toast: true,
//             position: 'bottom-end',
//             showConfirmButton: false,
//             timer: 3000
//           })
          
//           Toast.fire({
//             background:"#206bc4",
//             type: 'success',
//             title: "Updated Sucessfully",
//             color:"white"
//           });
         
//       }).catch((e) => {
//         const Toast = Swal.mixin({
//             toast: true,
//             position: 'bottom-end',
//             showConfirmButton: false,
//             timer: 5000
//           })
          
//           Toast.fire({
//             background:"#e63900",
//             type: 'error',
//             title: "Something Went Wrong",
//             color:"white"
//           });
           
//            });
// }

const changecommission = (updateData) => {


    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/customer_carrier_update_api`;
    let sendData = selectedCarrier 
    console.log("sendData",sendData)
   
    axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
        console.log("res ", res);
        fetchCarriers()
          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
          
          Toast.fire({
            background:"#206bc4",
            type: 'success',
            title: "Updated Sucessfully",
            color:"white"
          });
        
         
      }).catch((e) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 5000
          })
          
          Toast.fire({
            background:"#e63900",
            type: 'error',
            title: "Something Went Wrong",
            color:"white"
          });
           });
}
const updateCommission = () =>{
    changecommission(selectedCarrier)
}

 
  React.useEffect(() => {
    fetchCarriers();
  }, [])
  return (
    <div>
         <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       
       <section class="home-section">
               {/* <?php
               include('sub_header.php');
               ?> 
            */}
       <Sub_header/>
     
      <section id="payment_gateway_info" class="container-fluid">
    <section>
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div class="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                        <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li class="breadcrumb-item">
                                <a href="#">
                                    <svg class="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Settings</li>
                        </ol>
                    </nav>
                    <h2 class="h4">Master Settings</h2>
                </div>
                <div class="btn-toolbar mb-2 mb-md-0">
                    
                </div>
            </div>
            
    </section>  

    <section class="mb-3">
            
        <div class="row g-3">
        <div class="col-md-3 sticky-top custom-tab-293">
            {carriers.map((sub)=>(
                        <nav id="myTab" class="nav nav-pills flex-column custom_tab_89 text-center mb-5">
                        <a onClick={() => handleCarrierSelection(sub)} data-bs-toggle="pill" class={selectedCarrier.carrier_id==sub.carrier_id?"active nav-link":""} > <img src={`https://cdn.shipbees.in/images/${sub.carrier_image}`}alt="" class="img-fluid" /><br/> <small className="text-danger">{sub.carrier_name}</small>
                        </a>
                        </nav>
            ))}
        </div>
        <div class="col-md-9 tab-content">
            <article class="tab-pane fade show active" id="category_tab1">
                <div class="card border-0 shadow-sm">
                <div class="card-body">
                   
                    <div class="row">
                        <div class="col-12 col-md-12 ">
                            <div class="card">
                            <div class="card-body bg-light-c">
                            <h5> <i class="material-icons-outlined">flash_on</i> Live Production Mode Settings</h5>
                                <div class="row">
                                    { selectedCarrier.carrier_id!="1656377050" && selectedCarrier.carrier_id!="1656677340"?
                                    <div class="col-12 col-md-12 mb-3">
                                      {selectedCarrier.carrier_id!="1656377056" && selectedCarrier.carrier_id!="1656013556" && selectedCarrier.carrier_id!="1426513056" && selectedCarrier.carrier_id!="1345673056"  ? <label for="" class="form-label">Customer Code:</label>:<label for="" class="form-label">Account Code:</label>}
                                        <input type="text" class="form-control" id="" placeholder="Enter customer code"  value={selectedCarrier.customer_code} name="customer_code"  onChange={(e)=>inputHandleChange(e)}  />
                                    </div>
                                    :""}
                                    {selectedCarrier.carrier_id!="1656677340"?
                                    <div class="col-12 col-md-12 mb-3">
                                       {selectedCarrier.carrier_id=="1343170006"?<label for="" class="form-label">Password:</label>: <label for="" class="form-label">API-key:</label>}
                                        <input type="text" class="form-control" id="" placeholder="Enter api-key" name="api_key" value={selectedCarrier.api_key} onChange={(e)=>inputHandleChange(e)} />
                                    </div>
                                    :""}
                                   
                                
                                    
                                </div>
                            </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
                </div>
                </article>
                <div class="text-end mt-3">
            <button type="button" class="btn btn-primary btn-lg" onClick={updateCommission}>Update</button>
        </div>
        </div>
        </div> 

      

    </section>

</section>
</section>
    </div>
  )
}

export default Master_new_customer
