import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import axios from "axios";
import Moment from 'react-moment';

import ReactLoading from 'react-loading';
import exportValue from '../../apiconfig'
import Barcode from 'react-barcode';

const Print_receipts = () => {
    const location = useLocation()
    const {shipment_id} = useParams()
    console.log("shipment_id",shipment_id)

  

    const[state,setState]= React.useState({shipment_list:[]})
    console.log("state",state)
    const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})


    React.useEffect(() => {
      dimension_detail()
      shipment_detail()
    
      }, [])

    const shipment_detail=()=>{
        let dataToSend = {shipment_id:shipment_id };
   
      console.log("datatoSend",dataToSend)
      let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment_detail`;
     
     // console.log("headers => ", config.headers);
     
      
      axios.post(full_api, dataToSend, { headers: exportValue.headers })
          .then((res) => {
            console.log("responseJson => ", res);
            setState({shipment_list:res.data.output})
          
  
         
          })
          .catch((error) => {
              //Hide Loader
            //   setLoadingstate({...loadingstate,loading:false})
              console.log(error);
          });    

    }
    const dimension_detail = () =>{

      let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/company_info_api`;
      let sendData = {};
    
      axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
        setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
        console.log("rnmmmmm", res);
      }).catch((e) => {
        // toast.configure()
         //toast.error("Some thing went wrong")
        console.log("----error:   ", e);
      })
    }
 const  FunctionComponent = () => {
    return <Barcode value={shipment_id} />;
};
  return (
    <div>
        {state.shipment_list.map((sub)=>(
      <section class="mb-3 pt-5 bg-light p-5">
   <div class="container print_receipt_cs bg-white p-5">
    <div class="row">
       <div class="col-6 col-md-6">
        <h1>Shipbees</h1>
        <p>
            Bruderstrasse 27, Hamburg<br/>
            Hamburg, Germany - 20355
        </p>
        <p>
            Ph: 040 35715358
        </p>
       </div>
       <div class="col-6 col-md-6 text-end">
        <h2>AWB #{sub.shipment_id} </h2>
        {/* <img src="https://i.imgur.com/UFPpzfs.png" alt=""/> */}
        <FunctionComponent/>
       </div>
    </div>
    <hr/>
    <div class="row pt-5">
        <div class="col-12">
         <h5>Billing information</h5>
            <table class="table table-bordered">
             <thead>
                <tr>
                        <th>Order date</th>
                        <th>type</th>
                        <th>carrier name</th>
                        <th>service name</th>
                        <th>total weight</th>
                        <th>no of item</th>
                        <th>shipping fee</th>
                </tr>
             </thead>
             <tbody>
                <tr>
                <td><Moment format="MMM DD YYYY">
                        {new Date(sub.created*1000)}
            </Moment> <br/>
                            <small><Moment format="hh:mm">
                        {new Date(sub.created*1000)}
            </Moment></small>
                        </td>
                        <td>{sub.type}</td>
                        <td>{sub.carrier_name}</td>
                        <td>{sub.service_provider}</td>
                        <td>{sub.total_weight!=undefined && sub.total_weight!=""?sub.total_weight:""}({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_weight}</>
                                 ))})</td>
                        <td>{sub.items.length}</td>
                        <td>({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}){sub.rate_price}</td>
                </tr>
                <tr>
                    <td colspan="6" class="text-end">Total</td>
                    <td><strong>({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}){sub.rate_price}</strong></td>
                </tr>
                <tr>
                    <td colspan="6" class="text-end">Tax: VAT (18%)</td>
                    <td><strong>({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}){sub.tax_amount}</strong></td>
                </tr>
                <tr>
                    <td colspan="6" class="text-end">Insurance</td>
                    <td><strong>({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}){sub.insurance_amount}</strong></td>
                </tr>
                <tr>
                    <td colspan="6" class="text-end">Grand Total</td>
                    <td><h6>({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}){sub.total_amount}</h6></td>
                </tr>
                <tr>
                    <td colspan="6" class="text-end">Paid Amount:</td>
                    <td>({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}){sub.total_amount}</td>
                </tr>
             </tbody>
            </table>
        </div>
    </div> 
    <div class="row pt-5">
        <div class="col col-md">
         <h5>Shipment Details </h5>
            <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>items</th>
                    <th>invoice value</th>
                    <th>reference/order number</th>
                    <th>weight ({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_weight}</>
                                 ))})</th>
                    <th>quantity</th>
                    <th>price</th>
                    <th>total</th>
                  </tr>
                </thead>
                <tbody>
                {sub.items.map((item)=>(
                  <tr>
                    <td>
                        Name: <strong>{item.item_name}</strong><br/>
                        Description: <strong>{item.item_description}</strong><br/>
                        SKU: <strong>{item.item_sku}</strong><br/>
                        Invoice number: <strong>{sub.order_number}</strong><br/>
                        Invoice date: <strong><Moment format="MMM DD YYYY">
                        {new Date(sub.created*1000)}
            </Moment> </strong><br/>
                        product_url: <strong>google.com</strong><br/>
                        Dim ({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_dimension}</>
                                 ))}): <strong>H{item.item_height}xW{item.item_width}xL{item.item_length}</strong>

                    </td>
                    <td>({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}){item.item_value}</td>
                    <td>{sub.order_number}</td>
                    <td>{item.item_weight}({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_weight}</>
                                 ))})</td>
                    <td>1</td>
                    <td>({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}){item.item_value}</td>
                    <td>({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}){item.item_value}</td>
                  </tr>
                  ))}
                </tbody>
              </table>
        </div>
    </div> 

    <div class="row pt-5">
        <div class="col col-md">
            <h5>Pickup information</h5>
            <table class="table table-bordered">
            
                <tbody>
                  <tr>
                    <th>Sender Name</th>
                    <td>{sub.sender.name}</td>
                  </tr>
                  <tr>
                    <th>Address</th>
                    <td>{sub.sender.address}</td>
                  </tr>
                  <tr>
                    <th>Pickup Time</th>
                    <td>N/A</td>
                  </tr>
                  <tr>
                    <th>Phone Number</th>
                    <td>{sub.sender.mobile}</td>
                  </tr>
                  <tr>
                    <th>Postal Code</th>
                    <td>{sub.sender.pincode}</td>
                  </tr>
                  <tr>
                    <th>City</th>
                    <td>{sub.sender.city}</td>
                  </tr>
                  {/* <tr>
                    <th>State</th>
                    <td>Florida</td>
                  </tr> */}
                  <tr>
                    <th>Country</th>
                    <td>{sub.sender.country}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{sub.sender.email}</td>
                  </tr>
                </tbody>
               
            </table>
        </div>
        <div class="col col-md">
            <h5>Delivery information</h5>
            <table class="table table-bordered">
          
                <tbody>
                    <tr>
                      <th>Receiver Name</th>
                      <td>{sub.receiver.name}</td>
                    </tr>
                    <tr>
                      <th>Address</th>
                      <td>{sub.receiver.address}</td>
                    </tr>
                    <tr>
                      <th>Delivery Time</th>
                      <td>N/A</td>
                    </tr>
                    <tr>
                      <th>Phone Number</th>
                      <td>{sub.receiver.mobile}</td>
                    </tr>
                    <tr>
                      <th>Postal Code</th>
                      <td>{sub.receiver.pincode}
                      </td>
                    </tr>
                    <tr>
                      <th>City</th>
                      <td>{sub.receiver.city}</td>
                    </tr>
                    {/* <tr>
                      <th>State</th>
                      <td>Surrey</td>
                    </tr> */}
                    <tr>
                      <th>Country</th>
                      <td>{sub.receiver.country}</td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td>{sub.receiver.email}</td>
                    </tr>
                  </tbody>
          
            </table>
        </div>
        <div class="col col-md">
            <h5>Return information</h5>
            <table class="table table-bordered">
                <tbody>
                  <tr>
                    <th>Name</th>
                    <td>---</td>
                  </tr>
                  <tr>
                    <th>Address</th>
                    <td>---</td>
                  </tr>
                  <tr>
                    <th>Pickup Time</th>
                    <td>N/A</td>
                  </tr>
                  <tr>
                    <th>Phone Number</th>
                    <td>---</td>
                  </tr>
                  <tr>
                    <th>Postal Code</th>
                    <td>---</td>
                  </tr>
                  <tr>
                    <th>City</th>
                    <td>---</td>
                  </tr>
                  {/* <tr>
                    <th>State</th>
                    <td>Florida</td>
                  </tr> */}
                  <tr>
                    <th>Country</th>
                    <td>---</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>---</td>
                  </tr>
                </tbody>
            </table>
        </div>
    </div>  
    
    
         
   </div>
 </section>
))}
    </div>
  )
}

export default Print_receipts



 