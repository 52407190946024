//console.log("api config = ");
let detail = {};
let token = "";
if (localStorage.getItem("admin_login")) {
  detail = JSON.parse(localStorage.getItem("admin_login"));
  token = detail.token;
}
//console.log("->< ", localStorage.getItem('loggedIn'));
console.log("api detail = ", detail);
//let status = 0;

// if(token && token != '') {
//   status = 1
// }

const exportValue = {
  COD_COMISSION: true,
  SUPPORT: true,
  CUSTOMER_NEW_REGISTER: false,
  SMS_GATEWAY: false,
  CUSTOMER_KYC: false,
  REPORTS: true,

  host: "https://backend.shipbees.in",
  // host: "http://localhost:2053",

  // host: 'https://backend.shiport.in',
  // host: "http://localhost:2078",
  // host : 'https://shiprocketbackend.bbcloudhub.com',

  apiUrl: "https://backend.shipbees.in/v.1.0.1/web/query",
  // apiUrl: "http://localhost:2033/v.1.0.1/web/query",
  // apiUrl : "http://localhost:3020/v.1.0.1/web/query",

  version: "v.1.0.1",
  api: "admin/query",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    key: "E09F1280ghjghjg606C3BF43D882F479032F03B2C4172B795F997E03FA356604CA06A2C7090DBD6380454C39FD57BFCC6A24C712795021FB9501DBA54719285AFBC5AE5",
    AUTHORIZATIONKEYFORTOKEN: "",
    LOGINSTATUS: 0,
    DEVICEID: 1234567890,
    VERSION: 2.5,
    DEVICETYPE: 1,
    adminid: detail.admin_id ? detail.admin_id : "",
    token: detail.token ? detail.token : "",
    secretkey: detail.secret_key ? detail.secret_key : "",
    //device_name:encoded
  },
};

//console.log("api config => ");
export default exportValue;
